export enum ExternalRateIndex {
    RUGOLD = 'RUGOLD',
    GLDRUB_TOM = 'GLDRUB_TOM',
    SLVRUB_TOM = 'SLVRUB_TOM',
    USD000000TOD = 'USD000000TOD',
    EUR_RUB__TOD = 'EUR_RUB__TOD',
    CNY000000TOD = 'CNY000000TOD',
}

export const ExternalRateIndexTranslate = {
    [ExternalRateIndex.GLDRUB_TOM]: 'Золото',
    [ExternalRateIndex.SLVRUB_TOM]: 'Серебро',
    [ExternalRateIndex.USD000000TOD]: 'Доллар',
    [ExternalRateIndex.EUR_RUB__TOD]: 'Евро',
    [ExternalRateIndex.CNY000000TOD]: 'Юань',
}

export interface IIndexRate {
    // example: RUGOLD
    // Код индекса
    index: ExternalRateIndex

    // example: 4554.08
    // Значение курса индекса
    rate: number

    // example: 2023-01-01T11:53:33.779Z
    // Дата последнего изменения. RFC 3339 date-time.
    updated_at: string
}
