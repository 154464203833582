export interface ILoginServiceParams {
    login: string
    password: string
}
export interface ISignupParams {
    login: string
    password: string
    phone: string
    company: string
    fullname: string
}

export interface IResetPasswordInitParams {
    confirm_token: string
    password: string
}

export interface IResetEmailInitParams {
    user_uuid: string
    email: string
}

export type ChangePhoneType = {
    confirm_token: string
    phone: string
}

export type ChangeLoginType = {
    confirm_token: string
    login: string
    password: string
}

export type ResetUserProfileResponse = {
    profile_data_reset_url: string
}

export enum ChangeDataType {
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    PASSWORD = 'PASSWORD',
}
