import { CollectType } from './collectTypes'
import { SettlementsType } from './settlementsTypes'

const collectTypeTranslate = {
    [CollectType.account]: 'На лицевой счёт',
    [CollectType.bank_account]: 'На банковский счёт',
    [CollectType.none]: '---',
}

export const paymentMethodTranslate = (
    collect_type?: CollectType,
    settlements_type?: SettlementsType,
) => {
    switch (settlements_type) {
        case SettlementsType.platform:
            return collectTypeTranslate[collect_type || CollectType.none]
        case SettlementsType.direct:
            return 'Вне Платформы'
        default:
            return collectTypeTranslate[CollectType.none]
    }
}
