export enum TPeriodType {
    no_repeat = 'no_repeat',
    monthly = 'monthly',
    quarterly = 'quarterly',
    half_year = 'half_year',
    yearly = 'yearly',
}

export const couponPaymentFrequency: Record<TPeriodType, string> = {
    [TPeriodType.no_repeat]: 'Не повторять',
    [TPeriodType.monthly]: 'Ежемесячно',
    [TPeriodType.quarterly]: 'Раз в 3 месяца',
    [TPeriodType.half_year]: 'Раз в полгода',
    [TPeriodType.yearly]: 'Раз в год',
}

export interface INoRepeatFrequencyData {
    period_type: TPeriodType.no_repeat
    dates: string[]
}

export interface IOnceInNMonthsFrequencyData {
    period_type: Exclude<TPeriodType, TPeriodType.no_repeat>
    payment_date_start: string
    payment_date_end: string
    redeem_date?: string
}

export type TAddDates = INoRepeatFrequencyData | IOnceInNMonthsFrequencyData

export enum ECouponPaymentCollectType {
    account = 'account',
}

export interface CouponPaymentFacts {
    id: number
    payment_date: string
    price_per_dfa: number
    amount_dfa: number
    total_price: number
}

export interface TCouponPaymentDate {
    id?: number
    asset_id?: number
    payment_date: string
    notification_date?: string
    coupon_payment_facts?: CouponPaymentFacts[]
}

export interface ICouponPaymentCalendarTable {
    id?: number
    asset_id?: number
    payment_date: string
    notification_date?: string
    rowSpan: number
    cpf_id?: number
    cpf_payment_date?: string
    cpf_price_per_dfa?: number
    cpf_amount_dfa?: number
    cpf_total_price?: number
}

export enum ECouponPaymentMethod {
  FIXED = 'fixed',
  DETERMINED = 'determined',
  COMBINED = 'combined'
} 
