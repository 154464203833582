export enum UserRoles {
    DEFAULT = 'DEFAULT',
    SUPER = 'SUPER',
    TELLER = 'TELLER',
    LAWYER = 'LAWYER',
    AML = 'AML',
    CLIENT = 'CLIENT',
    OPERATOR = 'OPERATOR',
    LEGAL = 'LEGAL',
    CLIENT_PARTLY_BLOCK = 'CLIENT_PARTLY_BLOCK',
    CLIENT_FULL_BLOCK = 'CLIENT_FULL_BLOCK',
    INFOSEC = 'INFOSEC',
}

export enum UserRolesByScope {
    DEFAULT = 'default',
    SUPER = 'super',
    TELLER = 'teller',
    LAWYER = 'lawyer',
    AML = 'aml',
    CLIENT = 'client',
    OPERATOR = 'operator',
    LEGAL = 'legal',
    CLIENT_PARTLY_BLOCK = 'client_party_block',
    CLIENT_FULL_BLOCK = 'client_full_block',
    INFOSEC = 'infosec',
}

export const blockedRoles = [UserRoles.CLIENT_PARTLY_BLOCK, UserRoles.CLIENT_FULL_BLOCK]
// https://masterchain.atlassian.net/browse/DFA-8500?focusedCommentId=46925
// TELLER, LAWYER, AML, INFOSEC, SUPER
export const employeeRoles = [UserRoles.TELLER, UserRoles.LAWYER, UserRoles.AML, UserRoles.INFOSEC]
export const administrativeRoles = [UserRoles.TELLER, UserRoles.SUPER]

export const userRolesTranslate = {
    [UserRoles.DEFAULT]: 'Базовая ограниченная роль',
    [UserRoles.SUPER]: 'Суперпользователь',
    [UserRoles.TELLER]: 'Операционист',
    [UserRoles.LAWYER]: 'Юрист',
    [UserRoles.AML]: 'Специалист ПОД/ФТ',
    [UserRoles.CLIENT]: 'Клиент',
    [UserRoles.OPERATOR]: 'Оператор ИС',
    [UserRoles.LEGAL]: 'Юридическое лицо',
    [UserRoles.CLIENT_PARTLY_BLOCK]: 'Частично заблокированный пользователь',
    [UserRoles.CLIENT_FULL_BLOCK]: 'Полностью заблокированный пользователь',
    [UserRoles.INFOSEC]: 'Администратор ИБ',
}
