export const enum CurrentUserStatus {
    UNREGISTERED = 'UNREGISTERED',
    ONBOARDING_PROFILES = 'ONBOARDING_PROFILES',
    ONBOARDING_AGREEMENTS = 'ONBOARDING_AGREEMENTS',
    ONBOARDING_PROVISIONING = 'ONBOARDING_PROVISIONING',
    REGISTERED = 'REGISTERED',
}

export const enum UserType {
    INDIVIDUAL = 'INDIVIDUAL',
    ENTREPRENEUR = 'ENTREPRENEUR',
    LEGAL = 'LEGAL',
}

export const enum LastProfileStatus {
    DRAFT = 'DRAFT',
    OPERATOR_APPROVAL = 'OPERATOR_APPROVAL',
    LAWYER_APPROVAL = 'LAWYER_APPROVAL',
    AML_APPROVAL = 'AML_APPROVAL',
    IDENTIFICATION = 'IDENTIFICATION',
    REWORK = 'REWORK',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    ARCHIVED = 'ARCHIVED',
}
