import { CollectType, SettlementsType } from '../dfa'
import { ISecondaryOrder } from '../orders'

import { IEmissionDeal } from './emission'
import { ITransferDeal } from './transfer'

//мета информация для сделок при размещении выпуска ЦФА (type=invest)
export interface IInvestorDealIssueDealMeta {
    //идентификатор сделки
    deal_id: number
    //идентификатор заявки
    order_id: number
    order: IEmissionDeal
    //размер комиссии за приобретение ЦФА
    acquisition_fee_amount?: number
}

//мета информация для сделок при вторичном обращении ЦФА (type=secondary)
export interface IInvestorDealSecondaryDealMeta {
    //идентификатор сделки
    deal_id: number
    //информация о заявке на покупку
    buy_order: ISecondaryOrder
    //информация о заявке на продажу
    sell_order: ISecondaryOrder
    order?: IEmissionDeal
}

//мета информация для сделок погашения (type=repayment)
export interface IInvestorDealRepaymentDealMeta {
    //идентификатор сделки
    deal_id: number
    order?: IEmissionDeal
}

//мета информация для сделок мены (type=offer) и перехода прав (type=transfer)
export interface IInvestorDealTransferDealMeta {
    //идентификатор сделки
    deal_id: number
    order: ITransferDeal
    //размер комиссии за размещение оферты
    placement_fee_amount?: number
    //размер комиссии за акцепт оферты
    accept_fee_amount?: number
}

export type InvestorDealsMeta =
    | IInvestorDealTransferDealMeta
    | IInvestorDealRepaymentDealMeta
    | IInvestorDealSecondaryDealMeta
    | IInvestorDealIssueDealMeta

export interface IInvestorDeals {
    //Идентификатор сделки - UUID
    uuid: string
    //Тип сделки
    type: InvestorDealsType
    //Уникальный идентификатор перовой стороны сделки
    from_user_uuid: string
    //Полное наименование первой стороны
    from_user_full_name: string
    //Заблокирована ли первая сторона
    is_from_user_blocked: boolean
    //Уникальный идентификатор второй стороны сделки
    to_user_uuid: string
    //Полное наименование второй стороны
    to_user_full_name: string
    //Заблокирована ли вторая сторона
    is_to_user_blocked: boolean
    //Уникальный идентификатор выпуска ЦФА
    asset_id: number
    //Наименование выпуска ЦФА
    asset_title: string
    //Тикер выпуска ЦФА
    asset_ticker_symbol: string
    //Уникальный идентификатор эмитента выпуска ЦФА
    emitter_id: string
    //Полное наименование эмитента
    emitter_full_name: string
    //Количество ЦФА в сделке
    amount_dfa: number
    //Цена за единицу ЦФА
    price_per_dfa: number
    //Общая стоимость ЦФА
    total_price: number
    //Дата выполнения сделки
    executed_at: number
    collect_type: CollectType
    settlements_type: SettlementsType
    meta: InvestorDealsMeta
}

export type InvestorDealsType =
    | 'invest'
    | 'secondary'
    | 'offer'
    | 'transfer'
    | 'repayment'
    | 'accept_offer'
    | 'early_repayment'

export const DealsTypeTranslate: Record<InvestorDealsType, string> = {
    invest: ' первичное размещение',
    secondary: ' вторичное обращение',
    offer: ' оферта (мена)',
    transfer: ' переход прав',
    repayment: ' погашение',
    accept_offer: 'акцепт оферты',
    early_repayment: ' досрочное погашение',
}
