import { UploadFile } from 'antd/lib/upload/interface'

import { XOR } from '../../utils'

import { ExternalRateIndex } from '../common/externalRates'
import { ProfileType } from '../profile'
import { FilterField, TablePaginationParam } from '../request'
import { SignTransactionFullType } from '../sign'

import { IDfaAvailable, IDfaAvailablePrice } from './availableDfa'
import { CollectType } from './collectTypes'
import { SupplyInvestedType } from './countDfa'
import {
    ECouponPaymentCollectType,
    ECouponPaymentMethod,
    TCouponPaymentDate,
} from './couponPaymentTypes'
import { DecisionMakingType } from './decisionMakingType'
import { DfaStatusEnum } from './dfaStatus'
import { IssueAvailableInvestorsType } from './issueAvailableInvestorsType'
import { IssueTypeDfa } from './issueType'
import { IDfaOwnerBalance } from './owner'
import { PriceSource } from './priceSourceType'
import { RedemptionTypeDfa } from './redemptionType'
import { SettlementsType } from './settlementsTypes'
import { TypeOfLaw } from './typeOfLaw'

interface IDfaDecisionShort {
    emitter_address?: string
    emitter_id: string
    id?: number
    type_of_law?: TypeOfLaw
}

export interface IDfaDecision extends IDfaDecisionShort {
    emitter_link?: string
    handle_date?: number
    ois_info?: string
    ois_link?: string
    operator_id?: string
    issue_start_date?: number
    issue_end_date?: number // emitment_date
    issue_date?: number // фактическая дата эмиссии
    original_amount?: number
    original_price?: number
    redeem_date?: number
    redeem_price_per_dfa?: number
    current_redeem_price_per_dfa?: number // при определяемой цене погашения (redemption_type === 'flexible')
    price_per_dfa?: number
    reject_reason?: null | string
    upload_date?: number
    issue_restrictions: string
    issue_conditions: string
    document: string | UploadFile[]
    document_signature: string | UploadFile[]
    attachments: string | UploadFile[]
    admission_application: string | UploadFile[]
    admission_application_signature: string | UploadFile[]
    issue_available_investors_type: IssueAvailableInvestorsType
    issue_available_investors_type_limits?: IssueAvailableInvestorsType | null

    redeem_price?: number
    decision_id?: number
    repayment_fee_amount?: number

    address?: string
    issue_fee_lock_id?: string

    is_coupon_payment: boolean
    coupon_payment_method_type?: ECouponPaymentMethod
    coupon_payment_amount?: number
    coupon_payment_amount_description?: string
    coupon_payment_collect_type?: ECouponPaymentCollectType
    is_coupon_paid_before_holiday_or_weekend?: boolean
    coupon_payment_calendar?: TCouponPaymentDate[]
    // нет пока в свагере, но должно быть позже https://masterchain.atlassian.net/browse/DFA-7543
    coupon_settlement_type: 'account' | 'bank_account'
    // служебное поле для сверки для BE, при выпуске ЦФА
    issue_fee_amount: number
}

export interface IDfaCommon {
    id?: number
    title: string
    ticker_symbol: string
    emitter_id: string
    emitter_full_name: string
    status: DfaStatusEnum
    update_date?: number
    payment_collect_type?: CollectType
    repayment_collect_type?: CollectType
    current_repayment_collect_type?: CollectType
    repayment_settlements_type?: SettlementsType
    current_repayment_settlements_type?: SettlementsType
    issue_settlements_type: SettlementsType
    decision_making_type: DecisionMakingType
    is_all_investors_repayment_confirmed?: boolean
    repayment_confirmed_amount?: number
    issue_type: IssueTypeDfa
    total_supply_invested: number
    is_force_repayment: boolean
    investors?: string[]
    is_limited_circle_investors: boolean
    redemption_type: RedemptionTypeDfa
    redemption_description: string
    author?: AuthorDfaType

    emitter_account_id?: string // Требуется при создании ЦФА
    balance?: IDfaOwnerBalance // Пока что получаем отдельно
    price_source_type: PriceSource
    price_source?: ExternalRateIndex
    investment_spread?: number
    redemption_spread?: number
    supply_invested_type?: SupplyInvestedType
    min_supply_invested?: number
    max_supply_invested?: number

    available_original_price: number
    available_rest_price: number

    price_external?: number // внешняя цена, добавляем при получении ЦФА
    original_amount_dfa?: number // высчитывем отдельно
    rest_amount_dfa?: number // высчитывем отдельно

    is_early_repayment: boolean
    period_for_early_repayment?: number
    early_redemption_spread?: number

    total_early_repayment_amount?: number
    new_early_repayment_amount?: number
}

export interface IDfa extends IDfaCommon {
    decision: IDfaDecision | IDfaDecisionShort
}

export type IDfaEmitterInfo = {
    types: ProfileType[]
    short_name: string
    legal_address: string
    post_address: string
    inn: string
    websites: string[]
    ogrn_date: number
} & XOR<{ ogrn: string; full_name: string; kpp?: string }, { ogrnip: string }>

export interface IDfaFront extends IDfaCommon, IDfaDecision {
    emitter_info?: IDfaEmitterInfo
    available?: IDfaAvailable & Partial<IDfaAvailablePrice>
    early_repayment_type?: boolean
    issue_fee_amount: number
}

// TODO Partial<IDfaAvailable> для "ручного" добавления остатков
export interface IDfaBack extends Partial<IDfaCommon>, Partial<IDfaAvailable> {
    decision: IDfaDecision | IDfaDecisionShort
    early_repayment_type?: 'last_day' | 'period'
}

export interface IDfaDecissionFile {
    issue_title: string
    ticker_symbol: string
    issuer_title: string
    legal_address: string
    post_address: string
    registration_date: number
    registration_organization: string
    ogrn: string
    last_name: string
    first_name: string
    second_name: string
    www_address: string
    redeem_price: number
    redeem_date: number
    original_amount: number
    issue_end_date: number // emitment_date
    original_price: number
    issue_date: number
    money_account?: string
}

export type PostDfaRepaymentPayload = {
    asset_id: number
    skid: string
}
export type PutDfaRepaymentPayload = {
    asset_id: number
    repayment_fee: number
    redeem_price_per_dfa: number
    redemption_spread?: number
    transaction: SignTransactionFullType
}
export type ConfirmRepaymentPayload = {
    asset_id: number
    skid: string
}

export type ParamsForDfaDeals = {
    asset_id?: string
    user_id?: string
}

export interface IDfaLink {
    ois_link?: string
    emitter_link?: string
}

export interface IInjunction {
    user_uuid: string
    asset_id: number
    amount_dfa: number
    executor_uuid: string
    attachment_uuid: string
    description: string
}

export interface ResponseDfa extends Omit<IDfaBack, 'status'> {
    status: DfaStatusEnum[] | string[]
}

export type UploadResultDocx = {
    name: string
    docx: string
}

export type UploadDocx = {
    user_id: string
    asset_title: string
    asset_ticker_symbol: string
    number: number
    price_per_dfa: number
    original_amount: number
    redeem_price_per_dfa: number
    redeem_date: number
    issue_start_date: number
    issue_end_date: number
    investors?: string[]
}

export interface IMakeIssueFailed {
    dfaId: number
    reject_reason: string
    reject_reason_documents_uuid?: string[]
}

export interface IIssueStopRequest {
    asset_id: string
    issue_stop_reason: string
    issue_stop_reason_documents_uuid: string[]
}

export interface IIssueUnStopRequest {
    asset_id: string
    issue_unstop_reason: string
    issue_unstop_reason_documents_uuid: string[]
}

export interface IEmissionDfaPayload {
    asset_id?: number | string
    skid?: string
}

export type TransferDfaAutocomplete = {
    user_uuid: string
    full_name: string
    inn: string
    type: string
}

export enum TransferOwnershipOfferType {
    public = 'public',
    personal = 'personal',
}

export interface GetDfasParams extends TablePaginationParam {
    status?: DfaStatusEnum[]
    emitter_id?: string[]
    ticker_symbol?: FilterField<string>
}

export interface ICouponPaymentPayload {
    asset_id: number
    coupon_payment_calendar_id: number
    price_per_dfa: number
}

export const TransferOwnershipOfferTypeTranslate = {
    [TransferOwnershipOfferType.public]: 'Публичная оферта',
    [TransferOwnershipOfferType.personal]: 'Индивидуальное предложение',
}

export const CouponSettlementTypeTranslate = {
    account: 'На лицевые счета Владельцев',
    bank_account: 'На банковские счета Владельцев',
}

export enum AuthorDfaType {
    ois = 'ois',
    spvb = 'spvb',
}
